import React from "react";
import { Row, Col, Image, Container, Button, Form } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";

// img
import blog9 from "../../../assets/images/blog/01.jpg";
import blog3 from "../../../assets/images/blog/03.jpg";
import blog4 from "../../../assets/images/blog/04.jpg";
import blog5 from "../../../assets/images/blog/05.jpg";
import blog6 from "../../../assets/images/blog/06.jpg";
import blog7 from "../../../assets/images/blog/07.jpg";
import blog8 from "../../../assets/images/blog/08.jpg";

import user1 from "../../../assets/images/user/01.jpg";
import user2 from "../../../assets/images/user/02.jpg";
import user3 from "../../../assets/images/user/03.jpg";
import user4 from "../../../assets/images/user/04.jpg";

const BlogDetail = () => {
  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col lg="8">
              <Card className="card-block card-stretch card-height blog blog-detail">
                <Card.Body>
                  <div className="image-block">
                    <Image
                      src={blog9}
                      className="img-fluid rounded w-100"
                      alt="blog-img"
                    />
                  </div>
                  <div className="blog-description mt-3">
                    <h5 className="mb-3 pb-3 border-bottom">
                      There are many variations of passages.
                    </h5>
                    <div className="blog-meta d-flex align-items-center mb-3 position-right-side flex-wrap">
                      <div className="date date me-4 d-flex align-items-center">
                        <i className="material-symbols-outlined pe-2 md-18 text-primary">
                          calendar_month
                        </i>
                        2 Weeks ago
                      </div>
                      <div className="like date me-4 d-flex align-items-center">
                        <i className="material-symbols-outlined pe-2 md-18 text-primary">
                          thumb_up_alt
                        </i>
                        20 Like
                      </div>
                      <div className="comments date me-4 d-flex align-items-center">
                        <i className="material-symbols-outlined pe-2 md-18 text-primary">
                          mode_comment
                        </i>
                        82 Comments
                      </div>
                      <div className="share date me-4 d-flex align-items-center">
                        <i className="material-symbols-outlined pe-2 md-18 text-primary">
                          Share
                        </i>
                        Share
                      </div>
                    </div>
                    <p>
                      Voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo. Nemo
                      enim ipsam voluptatem quia voluptas sit aspernatur aut
                      odit aut fugit, sed quia consequuntur magni dolores eos
                      qui ratione voluptatem sequi nesciunt.
                    </p>
                    <Link
                      className="d-flex align-items-center"
                      to="#"
                      tabIndex="-1"
                    >
                      Read More{" "}
                      <i className="material-symbols-outlined fs-6">
                        arrow_forward_ios
                      </i>
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-block card-stretch card-height blog-post">
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">New Post</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <ul className="list-inline p-0 m-0">
                    <li className="mb-3">
                      <Row className="align-items-top pb-3 border-bottom">
                        <Col md="5">
                          <div className="image-block">
                            <Image
                              src={blog3}
                              className="img-fluid rounded w-100"
                              alt="blog-img"
                            />
                          </div>
                        </Col>
                        <Col md="7">
                          <div className="blog-description mt-1 mt-md-0">
                            <div className="date mb-1">
                              <Link to="#" tabIndex="-1">
                                4 Weeks ago
                              </Link>
                            </div>
                            <h6>All the Lorem Ipsum generators</h6>
                          </div>
                        </Col>
                      </Row>
                    </li>
                    <li className="mb-3">
                      <Row className="align-items-top pb-3 border-bottom">
                        <Col md="5">
                          <div className="image-block">
                            <Image
                              src={blog4}
                              className="img-fluid rounded w-100"
                              alt="blog-img"
                            />
                          </div>
                        </Col>
                        <Col md="7">
                          <div className="blog-description mt-1 mt-md-0">
                            <div className="date mb-1">
                              <Link to="#" tabIndex="-1">
                                3 Weeks ago
                              </Link>
                            </div>
                            <h6>All the Lorem Ipsum generators</h6>
                          </div>
                        </Col>
                      </Row>
                    </li>
                    <li className="mb-3">
                      <Row className="align-items-top pb-3 border-bottom">
                        <Col md="5">
                          <div className="image-block">
                            <Image
                              src={blog5}
                              className="img-fluid rounded w-100"
                              alt="blog-img"
                            />
                          </div>
                        </Col>
                        <Col md="7">
                          <div className="blog-description mt-1 mt-md-0">
                            <div className="date mb-1">
                              <Link to="#" tabIndex="-1">
                                2 Weeks ago
                              </Link>
                            </div>
                            <h6>All the Lorem Ipsum generators</h6>
                          </div>
                        </Col>
                      </Row>
                    </li>
                    <li className="mb-3">
                      <Row className="align-items-top pb-3 border-bottom">
                        <Col md="5">
                          <div className="image-block">
                            <Image
                              src={blog6}
                              className="img-fluid rounded w-100"
                              alt="blog-img"
                            />
                          </div>
                        </Col>
                        <Col md="7">
                          <div className="blog-description mt-1 mt-md-0">
                            <div className="date mb-1">
                              <Link to="#" tabIndex="-1">
                                1 Week ago
                              </Link>
                            </div>
                            <h6>All the Lorem Ipsum generators</h6>
                          </div>
                        </Col>
                      </Row>
                    </li>
                    <li className="mb-3">
                      <Row className="align-items-top pb-3 border-bottom">
                        <Col md="5">
                          <div className="image-block">
                            <Image
                              src={blog7}
                              className="img-fluid rounded w-100"
                              alt="blog-img"
                            />
                          </div>
                        </Col>
                        <Col md="7">
                          <div className="blog-description mt-1 mt-md-0">
                            <div className="date mb-1">
                              <Link to="#" tabIndex="-1">
                                3 days ago
                              </Link>
                            </div>
                            <h6>All the Lorem Ipsum generators</h6>
                          </div>
                        </Col>
                      </Row>
                    </li>
                    <li>
                      <Row className="align-items-top">
                        <Col md="5">
                          <div className="image-block">
                            <Image
                              src={blog8}
                              className="img-fluid rounded w-100"
                              alt="blog-img"
                            />
                          </div>
                        </Col>
                        <Col md="7">
                          <div className="blog-description mt-1 mt-md-0">
                            <div className="date mb-1">
                              <Link to="#" tabIndex="-1">
                                2 Days ago
                              </Link>
                            </div>
                            <h6>All the Lorem Ipsum generators</h6>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="12">
              <Card className="card-block card-stretch card-height blog user-comment">
                <Card.Header>
                  <div className="header-title">
                    <h4 className="card-title">User Comment </h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <Row>
                    <Col lg="12">
                      <Card className="card-block card-stretch card-height blog">
                        <Card.Body className="p-0">
                          <div className="d-flex align-items-center">
                            <div className="user-image mb-3">
                              <Image
                                className="avatar-80 rounded"
                                src={user1}
                                alt="#"
                                data-original-title=""
                                title=""
                              />
                            </div>
                            <div className="ms-3">
                              <h5>Kaya Scodelario</h5>
                              <p>Web Developer</p>
                            </div>
                          </div>
                          <div className="blog-description">
                            <p>
                              At vero eos et accusamus et iusto odio dignissimos
                              ducimus qui blanditiis praesentium voluptatum
                              atque corrupti quos dolores et quas molestias
                              excepturi sint occaecati cupiditate non provident.
                            </p>
                            <div className="d-flex align-items-center justify-content-between mb-2 position-right-side">
                              <Link
                                to="#"
                                className="comments d-flex align-items-center"
                              >
                                <i className="material-symbols-outlined pe-2 md-18 text-primary">
                                  mode_comment
                                </i>
                                82 comments
                              </Link>
                              <span className="font-size-12 text-warning">
                                <i className="material-symbols-outlined md-18">
                                  star_half
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg="12" className="ps-0 ps-md-5">
                      <Card className="card-block card-stretch card-height blog">
                        <Card.Body className="p-0">
                          <div className="d-flex align-items-center">
                            <div className="user-image mb-3">
                              <Image
                                className="avatar-80 rounded"
                                src={user2}
                                alt="#"
                                data-original-title=""
                                title=""
                              />
                            </div>
                            <div className="ms-3">
                              <h5>Tom Cruise</h5>
                              <p>Web Designer</p>
                            </div>
                          </div>
                          <div className="blog-description">
                            <p>
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout. The point of using
                              Lorem Ipsum Many desktop publishing packages and
                              web page editors now use Lorem Ipsum.
                            </p>
                            <div className="d-flex align-items-center justify-content-between mb-2 position-right-side">
                              <Link
                                to="#"
                                className="comments d-flex align-items-center"
                              >
                                <i className="material-symbols-outlined pe-2 md-18 text-primary">
                                  mode_comment
                                </i>
                                98 comments
                              </Link>
                              <span className="font-size-12 text-warning">
                                <i className="material-symbols-outlined md-18">
                                  star_half
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg="12">
                      <Card className="card-block card-stretch card-height blog">
                        <Card.Body className="p-0">
                          <div className="d-flex align-items-center">
                            <div className="user-image mb-3">
                              <Image
                                className="avatar-80 rounded"
                                src={user3}
                                alt="#"
                                data-original-title=""
                                title=""
                              />
                            </div>
                            <div className="ms-3">
                              <h5>Walter Hucko</h5>
                              <p>Web Designer</p>
                            </div>
                          </div>
                          <div className="blog-description">
                            <p>
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration in some form, by injected humour, or
                              randomised words which don't look even slightly
                              believable.
                            </p>
                            <div className="d-flex align-items-center justify-content-between mb-2 position-right-side">
                              <Link
                                to="#"
                                className="comments d-flex align-items-center"
                              >
                                <i className="material-symbols-outlined pe-2 md-18 text-primary">
                                  mode_comment
                                </i>
                                28 comments
                              </Link>
                              <span className="font-size-12 text-warning">
                                <i className="material-symbols-outlined md-18">
                                  star_half
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg="12" className="ps-0 ps-md-5">
                      <Card className="card-block card-stretch card-height blog">
                        <Card.Body className="p-0">
                          <div className="d-flex align-items-center">
                            <div className="user-image mb-3">
                              <Image
                                className="avatar-80 rounded"
                                src={user4}
                                alt="#"
                                data-original-title=""
                                title=""
                              />
                            </div>
                            <div className="ms-3">
                              <h5>Mark Walton</h5>
                              <p>Web Manager</p>
                            </div>
                          </div>
                          <div className="blog-description">
                            <p>
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration.randomised words which don't look even
                              slightly believable variations of passages.
                            </p>
                            <div className="d-flex align-items-center justify-content-between mb-2 position-right-side">
                              <Link
                                to="#"
                                className="comments d-flex align-items-center"
                              >
                                <i className="material-symbols-outlined pe-2 md-18 text-primary">
                                  mode_comment
                                </i>
                                82 comments
                              </Link>
                              <span className="font-size-12 text-warning">
                                <i className="material-symbols-outlined md-18">
                                  star_half
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                                <i className="material-symbols-outlined md-18">
                                  star
                                </i>{" "}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg="12">
                      <Card className="card-block card-stretch card-height blog">
                        <Card.Header className="px-0 pt-0">
                          <div className="header-title">
                            <h4 className="card-title">Your Comment</h4>
                          </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                          <Form>
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="email1">
                                Email address:
                              </Form.Label>
                              <Form.Control type="email" id="email1" />
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="pwd">Password:</Form.Label>
                              <Form.Control type="password" id="pwd" />
                            </Form.Group>
                            <Form.Group className="form-group">
                              <Form.Label htmlFor="exampleFormControlTextarea1">
                                Comment
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                id="exampleFormControlTextarea1"
                                rows={4}
                              />
                            </Form.Group>
                            <Form.Check className="mb-3 w-100">
                              <Form.Check.Input
                                type="checkbox"
                                id="remember-box"
                                value="option1"
                              />
                              <Form.Check.Label htmlFor="remember-box">
                                Remember me
                              </Form.Check.Label>
                            </Form.Check>
                            <div className="d-flex align-items-center">
                              <Button
                                type="button"
                                variant="btn btn-primary me-2"
                              >
                                Submit
                              </Button>
                              <Button type="button" variant="btn btn-danger">
                                Cancel
                              </Button>
                            </div>
                          </Form>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BlogDetail;
